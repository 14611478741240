import React from "react"
import { graphql, Link } from "gatsby"

import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faComment, faComments, faBookmark, faStar } from "@fortawesome/free-solid-svg-icons"

import MentionList from "../components/mention-list"

import { note, headerIcon} from "./appearances.module.scss"

import { contentBadge, tableOfContents, tableOfContentsList } from "./academic.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"


const PressPage = ({ data }) => {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())


    const highlights = data.allPressMentionsJson.edges.filter(
        e => e.node.featured
    )

    const interviews = data.allPressMentionsJson.edges.filter(
        e => e.node.interview
    )
    const mentions = data.allPressMentionsJson.edges.filter(
        e => !e.node.interview
    )

    return (
        <>
            <Seo title="Press" />
            <h1>Press</h1>
            <p>
                News outlets regularly interview me about{" "}
                <Link to={"/academic"}>my research</Link> and{" "}
                <Link to={"/"}>projects</Link> into computational humor,
                creative AI and large language models. Below is a non-exhaustive
                list of news and other media outlets interviewing me or
                mentioning my work.
                {/*, or*/}
                {/*interviewing me about my projects or my research*/}
                {/*into computational humor and creative artificial intelligence.*/}
                {/*A non-exhaustive list of news and other media outlets mentioning my work.*/}
                <p className={note}>
                    <em>
                        <strong>Note: </strong>
                        Listing an item does not imply endorsement of its
                        content.
                    </em>
                </p>
            </p>
            <p>
                If you'd like to interview me, you can contact me on my{" "}
                <Link to={"/contact"}>contact form</Link>.
            </p>
            <section className={tableOfContents}>
                <h2>
                    <FontAwesomeIcon
                        icon={faBookmark}
                        className={headerIcon}
                        size={"sm"}
                    />
                    Table of Contents
                </h2>
                <div>
                    <ul className={"fa-ul " + tableOfContentsList}>
                        <li>
                            <FontAwesomeIcon
                                icon={faStar}
                                className={headerIcon}
                                listItem
                            />
                            <AnchorLink to="/press#highlights">
                                Highlighted Press
                                <span className={contentBadge}>
                                    {highlights.length}
                                </span>
                            </AnchorLink>
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faComments}
                                className={headerIcon}
                                listItem
                            />
                            <AnchorLink to="/press#interviews">
                                Interviews
                                <span className={contentBadge}>
                                    {interviews.length}
                                </span>
                            </AnchorLink>
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faComment}
                                className={headerIcon}
                                listItem
                            />
                            <AnchorLink to="/press#mentions">
                                Mentions
                                <span className={contentBadge}>
                                    {mentions.length}
                                </span>
                            </AnchorLink>
                        </li>
                    </ul>
                </div>
            </section>
            <section>
                <h2 id={"highlights"}>
                    <FontAwesomeIcon icon={faStar} className={headerIcon} />
                    Highlighted Press
                </h2>
                <p>
                    Some highlighted articles for gaining quick insights into my research interests.
                </p>
                <MentionList mentions={highlights} displayProjects={true} hideFeatured={true}/>
            </section>
            <section>
                <h2 id={"interviews"}>
                    <FontAwesomeIcon icon={faComments} className={headerIcon} />
                    Interviews
                </h2>
                <p>
                    Below is a list of articles, videos and podcasts with
                    interviews I've given to news outlets and other media.
                </p>
                <MentionList mentions={interviews} displayProjects={true} />
            </section>
            <section>
                <h2 id={"mentions"}>
                    <FontAwesomeIcon icon={faComment} className={headerIcon} />
                    Mentions
                </h2>
                <p>
                    Below is a list of press that mention me or my work, but
                    where I had no or only little involvement in the creation of
                    the content of the press item.
                </p>
                <MentionList mentions={mentions} displayProjects={true} />
            </section>
        </>
    )
}

export default PressPage

export const query = graphql`
    query {
        allPressMentionsJson(
            sort: { fields: [date, hour], order: [DESC, DESC] }
        ) {
            edges {
                node {
                    id
                    ...MentionFragment
                }
            }
        }
        allTalksJson(sort: { fields: [date], order: DESC }) {
            edges {
                node {
                    id
                    ...TalkFragment
                }
            }
        }
    }
`
